const state = {
    activePage: 'login',
    progressPercent: 0
}

const getters = {
    activePage: state => {
        return state.activePage;
    },
    progressPercent: state => {
        return state.progressPercent;
    }
}

const actions = {
    updateActivePage({ commit }, activePage) {
        commit('updateActivePage', activePage);
    },
    updateProgressPercent({ commit }, progressPercent) {
        commit('updateProgressPercent', progressPercent);
    }
}

const mutations = {
    updateActivePage(state, activePage) {
        state.activePage = activePage; 
    },
    updateProgressPercent(state, progressPercent) {
        state.progressPercent = progressPercent;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
