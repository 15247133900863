import { required, email } from "vee-validate/dist/rules";
import { extend, setInteractionMode } from 'vee-validate';

extend("required", {
    ...required,
    message: "This field is required"
});

extend("email", {
    ...email,
    message: "This field must be a valid email"
});

// Need to add a rule for min and max length
// extend("min", {
//     validate(value, args) {
//         return value.length >= args.length;
//     },
//     message: 'This field must be at least {length} characters long.'
// });

// extend("max", {
//     validate(value, args) {
//         return value.length <= args.length;
//     },
//     message: 'This field must be no more than {length} characters long.'
// });

extend("upperCase", {
    validate(value) {
        return /[A-Z]/.test(value);
    },
    message: 'This field must contain at least one uppercase character.'
});

extend("lowerCase", {
    validate(value) {
        return /[a-z]/.test(value);
    },
    message: 'This field must contain at least one lowercase character.'
});

extend("special", {
    validate(value) {
        return /[!@#$%^&*]/.test(value);
    },
    message: 'This field must contain at least one special character.'
});

setInteractionMode('lazy');
