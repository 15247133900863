import router from '@/router';

const state = {
    isAuthenticated: false
}

const getters = {
    isAuthenticated: state => {
        return state.isAuthenticated;
    }
}

const actions = {
    updateAuthStatus({ commit }, { isAuthenticated, token }) {
        if (token) {
            localStorage.setItem('token', token);
        }
        
        commit('updateAuthStatus', isAuthenticated);
    },
    logout({ commit }) {
        localStorage.removeItem('token');
        commit('updateAuthStatus', false);
        router.push('/login');
    },
    contact() {
        router.push('/contact');
    },
    contactsuccess() {
        router.push('/contactsuccess');
    },
    supportsuccessful() {
        router.push('/supportsuccessful');
    }
}

const mutations = {
    updateAuthStatus(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated; 
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
