import Vue from 'vue';
import ApiService from '@/services/api';
import App from '@/App.vue';
import router from '@/router';
import '@/assets/scss/app.scss';
import store from '@/store';
import '@/services/validation';
import 'bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

Vue.component('loading-screen', Loading);

dayjs.extend(relativeTime);

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});

Vue.config.productionTip = false;

ApiService.init(process.env.VUE_APP_API_SERVER);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
