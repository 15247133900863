import Vue from 'vue';
import Vuex from 'vuex';

import session from '@/store/modules/session';
import page from '@/store/modules/page';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        session,
        page
    },
    string: debug
});
