import axios from 'axios';
import router from '@/router';
import store from '@/store';
//import app from '../main';

const ApiService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL;
        
        axios.interceptors.request.use(async function (config) {
            const token = localStorage.getItem('token');
            
            if (token) {
                config.headers.Authorization =  `Bearer ${token}`;
            }
        
            return config;
        });

        axios.interceptors.response.use(async function (response) {
            return response;
        }, async function (error) {
            if (error.response.status === 401) {
                // Redirect to login page
                localStorage.removeItem('token');
                store.actions.updateAuthStatus({ isAuthenticated: false });
                router.push('/login');
            } else {
                return Promise.reject(error);
            }
        });
    },
    get(resource) {
        return axios.get(resource);
    },
    post(resource, data) {
        return axios.post(resource, data);
    },
    put(resource, data) {
        return axios.put(resource, data);
    },
    delete(resource) {
        return axios.delete(resource);
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data);
    }
}

export default ApiService;
