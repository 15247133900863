<template>
    <div id="app">
        <nav-bar/>
        <transition name="fade">
            <router-view class="child-view"/>
        </transition>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        components: {
            'nav-bar': () => import('@/components/Nav-Bar')
        },
        methods: {
            ...mapActions(['updateAuthStatus'])
        },
        beforeMount() {
            const token = localStorage.getItem('token');

            if (token) {
                this.updateAuthStatus({ isAuthenticated: true });
            }
        },
        watch: {
            '$route' (to, from) {
                const toDepth = to.path.split('/').length
                const fromDepth = from.path.split('/').length
                this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
            }
        }
    }
</script>

<style lang="scss">
    html {
        height: 100vh;
    }

    body {
        background-color: transparent !important;
        color: #fff !important;
    }

    .fade-enter-active, .fade-leave-active {
  transition: opacity .75s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}

.child-view {
  position: absolute;
  width: 100%;
  transition: all .125s cubic-bezier(.55,0,.1,1);
}
</style>