import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
    if (localStorage.getItem('token')) {
        return next();
    }

    next({
        path: '/login',
        replace: true
    });
}

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: {
            isPublic: true
        }
    },
    {
        path: '/passwordReset',
        name: 'Password Reset',
        component: () => import(/* webpackChunkName: "login" */ '@/views/PasswordReset.vue'),
        meta: {
            isPublic: true
        }
    },
    {
        path: '/passwordResetSubmit/:token',
        name: 'Password Reset Submit',
        component: () => import(/* webpackChunkName: "login" */ '@/views/PasswordResetSubmit.vue'),
        meta: {
            isPublic: true
        },
        query: {
            token: ':token'
        },
        props: true
    },
    {
        path: '/join',
        name: 'join',
        component: () => import(/* webpackChunkName: "join" */ '@/views/Join.vue'),
        meta: {
            isPublic: true
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')
    },
    {
        path: '/contactsuccess',
        name: 'contactsuccess',
        component: () => import(/* webpackChunkName: "contactsuccess" */ '@/views/ContactSuccess.vue'),
    },
    {
        path: '/supportsuccessful',
        name: 'supportsuccessful',
        component: () => import(/* webpackChunkName: "supportsuccessful" */ '@/views/SupportSuccessful.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/assessment',
        name: 'assessment',
        component: () => import(/* webpackChunkName: "assessment" */ '@/views/Assessment.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/report/:id',
        name: 'report',
        component: () => import(/* webpackChunkName: "report" */ '@/views/Report.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/report/:id/upgrade-success',
        name: 'report-upgrade-success',
        component: () => import(/* webpackChunkName: "report-upgrade-success" */ '@/views/UpgradeSuccess.vue'),
        beforeEnter: authGuard
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.isPublic);

    if (isPublic && localStorage.getItem('token')) {
        return next({
            path: '/dashboard',
            replace: true
        });
    }

    store.dispatch('updateActivePage', to.name);
    store.dispatch('updateProgressPercent', 0);

    next();
});

export default router;
